<template>
    <div>
      <FormularioEdit
        :formulario="currentItem"
        :close="closeEdit"
      />

      <Resposta
        :formulario="formulario"
        :perguntas="perguntas"
        :close="closeEdit"
      />
        <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab title="Formulários">
                <div class="table-responsive">
                    <datatable :columns="table.columns"  :data="items" class="table">
                        <template slot-scope="{ row }" >
                            <tr>
                                <td class="highlight">{{ row.id }}</td>
                                <td>{{ row.titulo }}</td>
                                <td>{{ row.tipo }}</td>
                                <td>/formulario/{{ row.slug }}</td>
                                <td class="actions">
                                    <button type="button" class="button button-primary" v-on:click="openEdit(row)">
                                        <fa-icon icon="pencil-alt" /> 
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </datatable>
                </div>
            </CTab>
             <CTab title="Respostas">
                <div class="table-responsive">
                    <datatable :columns="table.columnsR"  :data="respostas" class="table">
                        <template slot-scope="{ row }" >
                            <tr>
                                <td class="highlight">{{ row.id }}</td>
                                <td align="center">{{ row.titulo }}</td>
                                <td align="center">{{ row.data }}</td>
                              
                                <td class="actions">
                                    <button type="button" class="button button-success" v-on:click="openResposta(row)">
                                        <CIcon name="cil-magnifying-glass"/> Visualizar
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </datatable>
                </div>
            </CTab>
            
        </CTabs>

    </div>
</template>
<script>
import { get, post, put, del } from '@/helpers/apiRequest';
import FormularioEdit from './components/FormularioEdit';
import Resposta from './Resposta';


export default {
  name: 'formularios',
  components: {    
    FormularioEdit, Resposta
  },
  data() {
    return {
      currentItem: {},
      loading: false,
      currentPage: 1,
      items: [],
      respostas: [],
      perguntas: [],
      aletrenativas:[],
      formulario: [],
      table: {
        filter: "",
        columns: [
          { label: "ID", field: "id",  align: "left", sortable: false },
          { label: "Título", field: "titulo", sortable: false },
          { label: "Tipo", field: "tipo", sortable: false },
          { label: "Slug", field: "slug", sortable: false },
          { label: "", field: "", sortable: false },
        ],
        columnsR: [
          { label: "ID", field: "id",  align: "left", sortable: false },
          { label: "Título", field: "titulo", sortable: false },
          { label: "Data", field: "data", sortable: false },
          { label: "", field: "", sortable: false },
        ],
        paginator: {},
      },
      descPlural: '',
      descSingular: '',

    };
  },
  methods: {
      loadDescricaoModulo() {
        const { modulos } = JSON.parse(localStorage.getItem('app'));
        const { descPlural, descSingular } = modulos.find(
          (m) => m.nome === 'ADMIN_FORMULARIOS'
        );
        this.descPlural = descPlural;
        this.descSingular = descSingular;
      },
      loadFormularios() {
          this.loading = false;
          get("/admin/formularios")
              .then((json) => {
                this.items = json;
                this.table.paginator = json.paginator;  
                this.loading = false;
              })
              .catch((err) => {});
      },
       loadRespostas() {
          this.loading = false;
          get("/admin/formularios/pergunta/respostas")
              .then((json) => {
                this.respostas = json;
                this.table.paginator = json.paginator;  
                this.loading = false;
              })
              .catch((err) => {});
      },
      openEdit(item) {
        get(`/admin/formularios/${item.id}`)
          .then((formulario) => {
            this.currentItem = formulario[0];
            this.$modal.show("formularioEdit");
        });
      },
      openResposta(resposta) {
        this.formulario = resposta;
        this.perguntas = resposta.perguntas;
        this.$modal.show("respostaModal");
      },
      refreshFormularios() {
        this.loadFormularios(this.currentPage);
      },
      closeEdit(){
        this.$modal.hide("formularioEdit");
        this.$modal.hide("respostaModal");
        this.refreshFormularios()
      }      
  },
  beforeMount() {
        this.loadRespostas();
        this.loadFormularios();    
        this.loadDescricaoModulo();
      },
}
</script>
