<template>
    <div>
        <PerguntaEdit
            :close="closeEdit"
            :item="currentItem"
            :formulario="formulario"
        />
        <div class="filters row" style="margin-top:0">
          <div class="col-1-1">
            <div class="pull-right">
                <button  class="button button-success button-block" v-on:click="createPergunta()">
                    <fa-icon icon="plus"/>
                    Nova
                </button>
            </div>
          </div>
        </div>
        <div class="table-responsive">
            <datatable :columns="table.columns"  :data="perguntas" class="table">
                <template slot-scope="{ row }" >
                    <tr>
                        <td class="highlight">{{ row.ordem }}</td>
                        <td>{{ row.descricao }}</td>
                        <td>{{ row.tipo }}</td>
                        <td>{{ row.obrigatorio }}</td>
                        <td class="actions">
                        <button
                            type="button"
                            class="button button-primary"
                            v-on:click="openEdit(row)"
                        >
                            <fa-icon icon="pencil-alt"/>
                        </button>
                        &nbsp;
                        <button
                            type="button"
                            name="button"
                            v-on:click="deleteConfirm(row)"
                            class="button button-error"
                        >
                            <fa-icon icon="trash-alt"/>
                        </button>
                        </td>
                    </tr>
                </template>
            </datatable> 
        </div>
        
    </div>
</template>

<script>
import Alternativas from './components/PerguntaEdit'
import PerguntaEdit from './components/PerguntaEdit.vue';
import { get, post, put, del } from '@/helpers/apiRequest';

  export default {
    name: 'perguntas',
    components: { Alternativas, PerguntaEdit },
    data() {
        return {
            currentItem: {},
            loading: false,
            currentPage: 1,
            items: [],
            table: {
                filter: "",
                columns: [
                    { label: "Ordem", field: "ordem", sortable: false },
                    { label: "Descrição", field: "descricao", sortable: false },
                    { label: "Tipo", field: "tipo", sortable: false },
                    { label: "Obrigatório", field: "obrigatorio", sortable: false },
                    { label: "", field: "", sortable: false },
                ],
                paginator: {},
            },
            descPlural: '',
            descSingular: '',
            };
    },
    methods: {
        deleteConfirm(item) {
            const {id} = item;
            this.$swal({
                title: 'Confirma Remover ?',
                text: 'Essa ação não poderá ser desfeita',
                showCancelButton: true,
                icon: 'warning',
            }).then((res) => {
                if (res.value) {
                    const   request = post('/admin/formularios/pergunta/delete/', {id} );
                            request
                                .then((success) => {
                                this.$swal({
                                    title: 'Sucesso!',
                                    text: 'Pergunta excluida com sucesso!',
                                    icon: 'success',
                                });
                            })
                            .catch((err) => {
                                this.loading = false;
                                this.$swal({
                                title: 'Falha ao remover',
                                text: err.message,
                                icon: 'error',
                                });
                            });
                    return;
                }
                })
                .catch(() => {
                });
        },
        closeCreate() {
            this.$modal.hide('perguntaEdit');
        },
        openEdit(item) {
            this.currentItem = item;
            console.log(this.currentItem)
            this.$modal.show('perguntaEdit');
        },
        closeEdit() {
            this.$modal.hide('perguntaEdit');
        },
        createPergunta(){
            this.currentItem = {};
            this.$modal.show('perguntaEdit');
        }
    },    
    props: ['perguntas', 'formulario'],
  };

</script>
